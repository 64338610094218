const logotext = "{OLPS}";
const meta = {
    title: "Olympeus Record",
    description: "Bienvenue chez Olympeus Record, prennez place !.",
};

const introdata = {
    title: "Olympeus Record",
    animated: {
        first: "Clips Vidéos",
        second: "Photographie",
        third: "Beatmaking",
        fourth: "Projets web",
        // add more if you'd like but make sure to update /src/pages/home/index.js Line 29
    },
    description: "\" Nous vous proposons des contenus audiovisuels et musicaux qui vous feront vivre des expériences uniques, qui vous feront rire, pleurer, réfléchir, et vous feront découvrir de nouveaux horizons \"",
    your_img_url: "https://i.ibb.co/x24FVFJ/LogoV3.jpg",
};

const dataabout = {
    title: "Qui sommes-nous ?",
    aboutme: "Nous sommes une équipe passionnés par l'audiovisuel, et nous avons à cœur de partager cette passion avec vous. Nous vous proposons une sélection de contenus de qualité et adapter à tous vos besoins.",
};
const worktimeline = [{
        jobtitle: "Film Director & Producer",
        where: "KAAZ",
        date: "@kaaz.__   ",
    },
    {
        jobtitle: "DOP",
        where: "U2PI Studio",
        date: "@u2pi.studio",
    },
    {
        jobtitle: "Photographe",
        where: "Numa",
        date: "@n_ma.sa",
    },
    {
        jobtitle: "Photographe & Assistant Caméra",
        where: "Jkei.prod",
        date: "@jkei_prod",
    },
];

const skills = [{
        name: "Clips Vidéos",
    },

    
    {
        name: "3D/Vfx",
    },
    
    {
        name: "Photographie",
    },

    {
        name: "Composition / Réalisation de projet musicaux",
    },

];

const dataportfolio = [{
        img: "https://i.ibb.co/LZBBMBc/IMG-2592.jpg",
        description: "Omerta - Love",   
        link: "https://youtu.be/UdXS29ZdMXw?si=s4fidt00RkIUICDM",
    }, 
    
    {
        img: "https://i.ibb.co/88w2zZK/Capture-d-e-cran-2024-02-14-a-21-25-44.png",
        description: "Limo - Cardio",
        link: "https://www.youtube.com/watch?v=5mdMJ-WLrtQ",
    }, 
       
    {
        img: "https://i.ibb.co/MShkrN5/IMG-3266.jpg",
        description: "Miedjia x JrLamelo - Gros Bolide",
        link: "https://www.youtube.com/watch?v=nffoIaVT2G8 ",
    },
    {
        img: "https://i.ibb.co/s1DWNmJ/Capture-d-e-cran-2023-09-06-a-21-57-17.png",
        description: "2Raffales - Vice",
        link: "https://www.youtube.com/watch?v=QXG4RE-7qQk",
    },
    {
        img: "https://i.ibb.co/L6h7fbp/Capture-d-e-cran-2023-09-06-a-21-59-24.png",
        description: "Jeffersxn - Salto",
        link: "https://www.youtube.com/watch?v=n9VpPWtcjrg",
    },
    {
        img: "https://i.ibb.co/LtmRMhc/Capture-d-e-cran-2023-09-06-a-22-01-25.png",
        description: "Azzedines - De l'autre côté du Miroir.",
        link: "https://www.youtube.com/watch?v=6Mvx7EbRBRg",
    },

   
];

const dataportfoliobts = [
{
    imgbts: "https://i.ibb.co/2hVFLws/IMG-3261.jpg",
    descriptionbts: "On retrouve la danseuse et choregraphe Mishaa dans les backstage du concert de toofan à la folie bergère ",
    linkbts: "https://www.instagram.com/p/C0cdAByL-Ny/",
},
{
    imgbts: "https://i.ibb.co/2F9BzGK/IMG-3265.jpg",
    descriptionbts: "Backstage de l´évenement Who´s Next x LaSunday avec Misha et Kidy.",
    linkbts: "https://www.instagram.com/p/C2707GVCtd_/",   
},
{
    imgbts: "https://i.ibb.co/QcSgj1q/IMG-3262.jpg",
    descriptionbts: "Retour dans les backstage du clip de Miedjia x JrLamelo - Gros Bolide",
    linkbts: "https://www.instagram.com/p/C0Esca-NmZk/",
},
{
    imgbts: "https://i.ibb.co/nsSGG61/IMG-3264.jpg",
    descriptionbts: "Retour sur le concert de JeyBrownie à la Maroquinerie !",
    linkbts: "https://www.instagram.com/p/CyOSwSetBhk/",
},
];

const contactConfig = {
    YOUR_EMAIL: "kaaz@olympeusrec.fr",
    description: "Nous vous attendez massivement !",
    // creat an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: "service_0h93qfw",
    YOUR_TEMPLATE_ID: "template_cm5n43e",
    YOUR_USER_ID: "MWLVntISCCG0zvJk8",
};

const socialprofils = {
    youtube: "https://www.youtube.com/channel/UCywwe8hOkpppEgNvTT1weVA",
    instagram: "https://www.instagram.com/olympeusrecords/",
};
export {
    meta,
    dataabout,
    dataportfolio,
    dataportfoliobts,
    worktimeline,
    skills,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};
